@import '../../../../rota_romanico/src/rota_romanico/scss/base/mixins';

#header-home{
  #full-screen-video-modal {
    .modal-dialog {
      max-width: 100%;
      height: 100%;
      padding: 0;
      margin-top: 0;
    }
    .modal-content {
      height: 100%;
      border-radius: 0;
      background-color: black;
      .modal-body {
        height: 100%;
        button {
          color: white;
          opacity: 0.7;
        }
        iframe {
          height: 90%;
        }
      }
    }
  }
  #landing-section {
    height: calc(100vh - #{$navigation-bar-height});
    @media (orientation: landscape) {
        height: 90vh;
    }
    position: relative;
    overflow: hidden;
    width: 100%;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .overlay {
      @include default-overlay(grey, .3);
    }
  }
}

#featured-phrase-section{
  .featured-phrase-row{
    position: relative;
  }
  .featured-phrase{
    position: absolute;
    background-color: white;
    bottom: 0;
    padding: 20px 50px 0px 50px;
    @include mq(width, 'sm', max) {
        position: relative;
        padding-left: 15px;
        padding-right: 15px;
    }
    @include mq(height, 'xs', max){
      position: relative;
    }
    .intro-title{
      text-transform: uppercase;
      color: $main-color;
      font-size: 2rem;
    }
    .featured-phrase-lead {
        color: #7e7d6c;
        font-size: 18px;
    }
    .landing-text{
      top: -40px;
      #play-video {
        color: white;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      #scroll-down {
        color: white;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

#highlight-section{
  .carousel-item {
    .title {
      @include text-max-lines(4);
    }

    .slider-img, .dark-overlay {
      transition: all 0.25s;
    }

    &:hover {
      .slider-img {
        transform: scale(1.05);
        .dark-overlay {
          opacity: 0.15;
        }
      }
    }
  }

  @include mq(width, 'md', min){
    .big-section{
      height: 700px;
    }
    .medium-section{
      height: 500px;
    }
    .small-section{
      height: 250px;
    }
    .h-60{
      height: 60%;
    }
    .h-40{
      height: 40%;
    }
    .l-15{
      left: 15px;
    }
    .half-height {
        height: 50%;
    }
  }

  @include mq-between(width, 'xs', 'md') {
    .big-item{
      height: 350px;
    }
    .medium-item{
      height: 300px;
    }
    .small-item{
      height: 200px;
    }
    .ex-small-item{
      height: 200px;
    }
    .slider5-3{
        height: 100% !important;
    }
  }

  @include mq(width, 'xs', max){
    .big-item{
      height: 350px;
    }
    .medium-item{
      height: 300px;
    }
    .small-item{
      height: 200px;
    }
    .ex-small-item{
      height: 150px;
    }
    .slider2-1{
        height: 300px;
    }
    .tp{
      padding-top: 15px;
    }
    .lp{
      padding-left: 15px;
    }
  }
  @include mq-between(width, xs, md) {
    .py-15{
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
    .pl0{
      padding-left: 0;
    }
    .pl-15{
      padding-left: 15px !important;
    }
  }
  @include mq(width, 'xs', min){
    .no-pl{
      padding-left: 0;
    }
    .full-h{
      height: 100%;
    }
    .half-h{
      height: 50%;
    }
    .mb-7{
      margin-bottom: 7px;
    }
    .mt-7{
      margin-top: 7px;
    }
  }
}

.carousel-indicators{
  top: 20px;
  right: 20px;
  bottom: auto !important;
  left: auto !important;
  justify-content: right;
  margin-right: 0;
  li{
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
}

.carousel-inner {
    .carousel-item {
        .d-block {
            background-size: cover;
            background-position: center;
        }
    }
}

.create-route {
    padding: 40px;
    background-size: cover;
    background-position: center;

    h2, h4 {
        margin-bottom: 10px;
        color: white;
    }

    .create-route-form {
        input, select {
            width: 100%;
            height: 45px;
            margin-bottom: 20px;
            border: 0;
        }

        span {
            width: 100%;
        }

        input {
            padding: 0px 10px;
        }

        select {
            -webkit-appearance: none;
            border-radius: 0;
            background-position: calc(100% - 12px);
            background-size: 16px;
            background-repeat: no-repeat;
        }

        .origin-suggestion {
            margin: 0;
            padding: 4px 10px;
        }

        .aa-dropdown-menu {
            top: 45px !important;
            max-height: 250px;
            overflow-y: auto;
            border: 1px solid #CCC;
            background-color: white;

            .aa-cursor {
                background-color: $second-color;
                color: white;
                cursor: pointer;
            }
        }

        #create-route-btn {
            display: inline-block;
            padding: 10px 24px;
            background-color: rgba(0, 0, 0, 0.5);
            border: 0;
            color: white;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                background: rgba(0, 0, 0, 0.3);
            }
        }
    }
}

@include mq(width, 'xs', max) {
    .text-content {
        padding: 0 15px !important;
    }
}
